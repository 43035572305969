import React from "react"
import PropTypes from "prop-types"
import CountUp from 'react-countup';

import { Container, StatLabel, StatNumber } from "./styles"

function StatGridItem({ endCount = 10, labelOfStat = "labelOfStat", ended= false }) {
  return (
    <Container>
      <StatNumber>
       <CountUp start={0} end={ended? endCount: 0}  /> 
      </StatNumber>
      <StatLabel>{labelOfStat}</StatLabel>
    </Container>
  )
}


//ended is when the count up stops
const propTypes = {
  labelOfStat: PropTypes.string.isRequired,
  endCount: PropTypes.number.isRequired,
  ended: PropTypes.bool.isRequired
}

StatGridItem.propTypes = propTypes

export default StatGridItem
