import React from "react"
import {
  Container,
  ColumnOne,
  ColumnTwo,
  StepListItemTextColumn,
  StepBottom,
  StepListItemImage,
} from "./styles"
import PropTypes from "prop-types"
import { theme } from "../../GlobalStyle"
import { LandingParagraphOne, LandingTitleTypeTwo } from "../styles"

function StepListSectionItem({
  heading = "Lorem ipsum dolor sit amet, consectetur adipisci",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus. Donec scelerisque sollicitudin enim eu venenatis. Duis tincidun",
  columnImageSrc = (theme.images.findanherbHeroImage =
    theme.images.findanherbHeroImage),
  showStepBottom = false,
  flipColumns = false,
}) {
  return (
    <Container showStepBottom={showStepBottom} flipColumns={flipColumns}>
      <ColumnOne>
        <StepListItemTextColumn>
          <LandingTitleTypeTwo>{heading}</LandingTitleTypeTwo>
          <LandingParagraphOne>{description}</LandingParagraphOne>
        </StepListItemTextColumn>
      </ColumnOne>

      <ColumnTwo flipColumns={flipColumns}>
        <StepListItemImage src={columnImageSrc} />
        {showStepBottom ? (
          <StepBottom
            flipColumns={flipColumns}
            src={theme.images.stepLineBottom}
          />
        ) : (
          undefined
        )}
      </ColumnTwo>
    </Container>
  )
}

// Heading should be less than 100 characters
// Description should be less than 201 characters
// columnImageSrc should be

StepListSectionItem.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  columnImageSrc: PropTypes.string.isRequired,
  showStepBottom: PropTypes.bool.isRequired,
  flipColumns: PropTypes.bool.isRequired,
}

export default StepListSectionItem
