import React from "react"
import LandingLayout from "../components/Layout/LandingLayout"
import UpSalePage from "../components/UpSalePage"
import { useStaticQuery } from "gatsby"

const AppPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ogImage: file(relativePath: { eq: "og-image-app-page.png" }) {
        id
        childImageSharp {
          fixed {
            src
            height
            width
          }
        }
      }
    }
  `)

  const {
    ogImage: { childImageSharp },
  } = data

  return (
    <LandingLayout
      seoProps={{
        title: "FREE Herb Encyclopedia Mobile App for iPhone and Android",
        image: childImageSharp.fixed,
      }}
    >
      <UpSalePage />
    </LandingLayout>
  )
}

export default AppPage
