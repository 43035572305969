import styled from 'styled-components'
import { BreakpointTablet, BreakpointMobile } from '../../GlobalStyle'

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: var(--LandingMaxPageWidth);
    margin-bottom: 100px;
    padding-left: var(--LandingPagePaddingLeftRight);
    padding-right: var(--LandingPagePaddingLeftRight);

`

export const TheGrid = styled.div`
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    grid-gap: 30px;
    width: 100%;

    @media only screen and (max-width: ${BreakpointTablet + 300 + 'px'}) {
        grid-template-columns:  1fr;
    }
`

export const GridWrapper = styled.div`
 
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h2{
        margin-top: 0px;
        font-family: var(--Font1);
        margin-bottom: 20px;
    }
`

