import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Container,
  HeadingSmallLanding,
  MySpan,
  StatGridContainer,
  StatGridWrapper,
} from "./styles"
import VisibilitySensor from "react-visibility-sensor"
import StatGridItem from "./StatGridItem"
function DatabaseCollectionStats() {
  let [ended, setEnded] = useState(false)

  const onChange = isVisible => {
    if (isVisible && ended === false) {
      setEnded(true)
    }
  }
  const data = useStaticQuery(graphql`
    query dataBaseCollectionStatsQuery {
      allMongodbHerbsHerbs(
        filter: { draft: { eq: false }, safeToPublish: { eq: true } }
      ) {
        totalCount
      }
      allMongodbHerbsAilments(
        filter: { safeToPublish: { eq: true }, draft: { eq: false } }
      ) {
        totalCount
      }
      allMongodbHerbsRemedies {
        totalCount
      }
    }
  `)

  const {
    allMongodbHerbsAilments: { totalCount: ailmentCount },
    allMongodbHerbsHerbs: { totalCount: herbCount },
    allMongodbHerbsRemedies: { totalCount: remedyCount },
  } = data

  return (
    <Container>
      <VisibilitySensor onChange={e => onChange(e)}>
        <MySpan />
      </VisibilitySensor>

      <StatGridContainer>
        <HeadingSmallLanding>
          We have an encycolpedia that keeps on growing
        </HeadingSmallLanding>
        <StatGridWrapper>
          <StatGridItem ended={ended} endCount={herbCount - 1} labelOfStat={"Herbs"} />
          <StatGridItem ended={ended} endCount={ailmentCount - 1} labelOfStat={"Ailments"} />
          <StatGridItem ended={ended}
            endCount={remedyCount - 1}
            labelOfStat={"Natural Remedies"}
          />
        </StatGridWrapper>
      </StatGridContainer>
    </Container>
  )
}

export default DatabaseCollectionStats
