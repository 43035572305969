import React from 'react'
import PropTypes from 'prop-types'
import { ReviewContainer, CustomerWrapper } from './styles'
import { theme } from '../../../GlobalStyle'

function ReviewItem({review}) {
    let {title = "review headline", name = "customer name", position = "customer position", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus. Donec scelerisque sollicitudin", avatar = theme.avatars[0]} = review;
    return (
        <ReviewContainer>
            <div className="grid-title-and-paragraph">
                <h2 className="grid-title">{title}</h2>
                <p className="grid-paragraph">
                    {description}
                </p>
            </div>

            <CustomerWrapper>
                <img alt={`"${title}" - ${name}`} width={50} height={50} src={avatar} className="customer-avatar" />
                <div className="customer-name-and-position">
                    <h5 className="customer-name">
                        {name}
                    </h5>
                    <h6 className="customer-position">
                        {position}
                    </h6>
                </div>
            </CustomerWrapper>
        </ReviewContainer>
    )
}


ReviewItem.propTypes = {
    review: PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        position: PropTypes.string,
        avatar: [PropTypes.string, PropTypes.bool],

        description: PropTypes.string.isRequired,
    })
 
  
}

export default ReviewItem