import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { appDownloadURLS, theme } from "../Layout/GlobalStyle"
import DatabaseCollectionStats from "../Layout/LandingLayout/DatabaseCollectionCountStats/DatabaseCollectionStats"
import HeroSection from "../Layout/LandingLayout/HeroSection"
import Reviews from "../Layout/LandingLayout/Reviews"
import StepListSectionItem from "../Layout/LandingLayout/StepListSectionItem"
import { SectionTitle } from "../Layout/LandingLayout/styles"
import { Container, BackdropSection } from "./styles"

function UpSalePage() {
  const data = useStaticQuery(graphql`
    query {
      pictureOne: file(
        relativePath: {
          eq: "app_splash_images/welcome-to-findanherb-splash.png"
        }
      ) {
        id
        size
        relativePath
        childImageSharp {
          id
          fixed(
            jpegQuality: 100
            jpegProgressive: true
            quality: 100
            pngQuality: 100
            width: 1000
          ) {
            src
            srcSet
            originalName
            base64
          }
        }
      }
      pictureTwo: file(
        relativePath: {
          eq: "app_splash_images/enter-symptoms-findanherb-splash.png"
        }
      ) {
        id
        size
        relativePath
        childImageSharp {
          id
          fixed(
            jpegQuality: 100
            jpegProgressive: true
            quality: 100
            pngQuality: 100
            width: 1000
          ) {
            src
            srcSet
            originalName
            base64
          }
        }
      }
      pictureThree: file(
        relativePath: {
          eq: "app_splash_images/possible-solutions-findanherb-splash.png"
        }
      ) {
        id
        size
        relativePath
        childImageSharp {
          id
          fixed(
            jpegQuality: 100
            jpegProgressive: true
            quality: 100
            pngQuality: 100
            width: 1000
          ) {
            src
            srcSet
            originalName
            base64
          }
        }
      }
      pictureFour: file(
        relativePath: { eq: "app_splash_images/ailment-findanherb-splash.png" }
      ) {
        id
        size
        relativePath
        childImageSharp {
          id
          fixed(
            jpegQuality: 100
            jpegProgressive: true
            quality: 100
            pngQuality: 100
            width: 1000
          ) {
            src
            srcSet
            originalName
            base64
          }
        }
      }
      pictureFive: file(
        relativePath: {
          eq: "app_splash_images/products-herb-relatives-splash-findanherb.png"
        }
      ) {
        id
        size
        relativePath
        childImageSharp {
          id
          fixed(
            jpegQuality: 100
            jpegProgressive: true
            quality: 100
            pngQuality: 100
            width: 1000
          ) {
            src
            srcSet
            originalName
            base64
          }
        }
      }
    }
  `)

  const landingHeroImage = data.pictureOne.childImageSharp.fixed.src
  const landingImageTwo = data.pictureTwo.childImageSharp.fixed.src
  const landingImageThree = data.pictureThree.childImageSharp.fixed.src
  const landingImageFour = data.pictureFour.childImageSharp.fixed.src
  const landingImageFive = data.pictureFive.childImageSharp.fixed.src

  return (
    <Container>
      <BackdropSection
        paddingTop={"200px"}
        paddingBottom={"200px"}
        paddingBottomMobile={"50px"}
        paddingTopMobile={"120px"}
        backgroundColor="var(--Color1LightestLowestOpacity)"
      >
        <HeroSection
          description={
            "FindanHerb offers a wide-ranging database of symptoms to help pinpoint what you need. From headaches to arthritis and everything in between. Join the app to gain access to a growing herb encyclopedia and health community."
          }
          columnImageSrc={landingHeroImage}
          iosDownloadLink={appDownloadURLS.ios}
          backdropImage={theme.images.heroBackdrop}
          heading={"Find natural remedies for ailments"}
        />
      </BackdropSection>
      <BackdropSection paddingBottomMobile={"40px"} paddingTopMobile={"40px"}>
        <DatabaseCollectionStats />
      </BackdropSection>

      <BackdropSection
        paddingBottomMobile={"0px"}
        paddingTopMobile={"20px"}
        paddingBottom={"0px"}
        paddingTop={"50px"}
      >
        <SectionTitle>How FindAnHerb works</SectionTitle>
        <StepListSectionItem
          columnImageSrc={landingImageTwo}
          description="From headaches to arthritis and everything in between. Join the app to gain access to a growing herb encyclopedia and health community."
          heading={
            "FindanHerb offers a wide-ranging database of symptoms to help pinpoint what you need."
          }
          showStepBottom={true}
        />
        <StepListSectionItem
          heading={
            "Figure out what ailment you may have based on the symptoms you selected"
          }
          description="Learn information about which natural remedies may help you. Browse our growing collection to find what may be the cause of your symptoms."
          flipColumns={true}
          showStepTop={true}
          showStepBottom={true}
          columnImageSrc={landingImageThree}
        />
        <StepListSectionItem
          heading="Get access to our growing library of natural remedies"
          description="Users can vote on popular herbs and remedies that have helped them in their health journey. FindAnHerb members can favorite herbs and add them to a personal list for future use. Review herbs and remedies to help others in the community discover new ways of improving their health. "
          flipColumns={false}
          showStepTop={true}
          showStepBottom={true}
          columnImageSrc={landingImageFour}
        />
        <StepListSectionItem
          columnImageSrc={landingImageFive}
          heading="Easily shop and purchase products containing the herbs you need."
          description="FindAnHerb members can purchase trusted and reviewed products through the app. Read reviews and recommendations from other users about their experiences."
          flipColumns={true}
          showStepTop={true}
        />
      </BackdropSection>
      <Reviews />
    </Container>
  )
}

export default UpSalePage
