import styled from 'styled-components'
import { BreakpointMobile } from '../../GlobalStyle'


export const Container = styled.div`
  position: relative;
  /* border: 1px solid blue; */

  width: 100%;
  padding-right: var(--LandingPagePaddingLeftRight);
  padding-left: var(--LandingPagePaddingLeftRight);
  
 
`


export const MySpan = styled.span`
    height: 20px;
    width: 20px;
    position: absolute;
    top: 150px;
    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        top: 155px;
    }
    
`

export const HeadingSmallLanding = styled.p`
  letter-spacing: 0.05em;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  font-family: var(--Font1);
  text-transform: uppercase;
  padding-bottom: 20px;
  color: var(--TextTitleColor1);

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    font-size: 16pt;
    line-height: 22pt;
  }

`

export const StatGridContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 800px;
  
  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    max-width: initial;
  }

`

export const StatGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }


`