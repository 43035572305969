import styled from 'styled-components'
import { BreakpointTablet } from '../../../GlobalStyle'



export const CustomerWrapper = styled.div`
    display: flex;
    min-height: 55px;
    text-overflow: ellipsis;
    overflow: hidden;
    .customer-name-and-position{
        display: flex;
        flex-direction: column;
        padding-left: 12px;
    }

    .customer-name{
        text-align: left;
        font-size: medium;
        line-height: 20.2px;
        font-weight: initial;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .customer-position{
        font-size: small;
        color: #718096;
        font-family: var(--Font2);
        text-align: left;
        line-height: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .customer-avatar{
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }

    @media only screen and (max-width: ${BreakpointTablet + 300 + 'px'}) {
        height: 45px;
        margin-top: 15px;
        .customer-name{
            text-align: left;
            line-height: 15px;
            font-size: small;
            line-height: 18px;
        }

        .customer-avatar{
          width: 40px;
          height: 40px;
      }
    }
`

export const ReviewContainer = styled.div`
    padding: 15px;
    box-shadow: 0 10px 28px rgba(0,0,0,.08);
    border: 1 solid #e2e8f0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 400px;
    border-radius: 4px;

    .grid-title-and-paragraph{
        display: flex;
        flex-direction: column;
    }
    .grid-title{
        font-size: 20px;
        font-family: var(--Font1);
        font-weight: bold;
        text-align: left;
        line-height: 30px;
    }

    .grid-paragraph{
        text-align: left;
        font-size: medium;
        line-height: 24px;
        font-family: var(--Font2);
        margin-top: 15px;
        margin-bottom: 25px;
    }

    @media only screen and (max-width: ${BreakpointTablet + 300 + 'px'}) {
        min-height: initial;
        grid-gap: 20px;
     
    }

` 