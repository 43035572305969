import styled from 'styled-components';
import { BreakpointMobile } from '../Layout/GlobalStyle';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    align-items: center;

`

export const BackdropSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${props => props.paddingTop? props.paddingTop: "var(--LandingSectionPaddingTopBottom)"};
    padding-bottom: ${props => props.paddingBottom? props.paddingBottom: "var(--LandingSectionPaddingTopBottom)"};
    ${props => props.backgroundImage? `background-image: url(${props.backgroundImage})`: undefined};
    ${props => props.backgroundColor? `background-color: ${props.backgroundColor};`: undefined};
    width: 100vw;
    align-items: center;
    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        padding-top: ${props => props.paddingTopMobile? props.paddingTopMobile: "var(--LandingSectionPaddingTopBottom)"};
        padding-bottom: ${props => props.paddingBottomMobile? props.paddingBottomMobile: "var(--LandingSectionPaddingTopBottom)"};    
    }


`

