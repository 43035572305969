import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StatNumber = styled.p`
    font-size: 45pt;
    font-family: var(--Font1NotCondensed);
    font-weight: bold;
    color: var(--Color1);
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 45pt;
    text-align: center;
`

export const StatLabel = styled.div`
text-align: center;
font-size: 15pt;
font-family: var(--Font1);
text-transform: uppercase;
color: var(--Color1);
font-weight: bold;
`
