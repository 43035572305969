import React from "react"
import { GridWrapper, TheGrid, Container } from "./style"
import ReviewItem from "./ReviewItem"
import { HeadingSmallLanding } from "../DatabaseCollectionCountStats/styles"
import { graphql, useStaticQuery } from "gatsby"

function Reviews() {

  const data = useStaticQuery(graphql`
  query   {
    avatarOne: file(
          relativePath: {
            eq: "review-avatar-one.png"
          }
        ) {
          id
          size
          relativePath
          childImageSharp {
            id
            fixed(
              jpegQuality: 100
              jpegProgressive: true
              quality: 100
              pngQuality: 100
              width: 50
              height: 50
            ) {
              src
              srcSet
              originalName
              base64
            }
          }
        }
      avatarTwo: file(
          relativePath: {
            eq: "review-avatar-two.png"
          }
        ) {
          id
          size
          relativePath
          childImageSharp {
            id
            fixed(
              jpegQuality: 100
              jpegProgressive: true
              quality: 100
              pngQuality: 100
              width: 50
              height: 50
            ) {
              src
              srcSet
              originalName
              base64
            }
          }
        }
     avatarThree: file(
          relativePath: {
            eq: "review-avatar-three.png"
          }
        ) {
          id
          size
          relativePath
          childImageSharp {
            id
            fixed(
              jpegQuality: 100
              jpegProgressive: true
              quality: 100
              pngQuality: 100
              width: 50
              height: 50
            ) {
              src
              srcSet
              originalName
              base64
            }
          }
        }
  }
  
  `)

  const {avatarOne, avatarTwo, avatarThree} = data;
  console.log(data, 'this is data')
  let reviews = [
    {
      name: "Amelia",
      position: "Herbalist",
      title: "Easy to use",
      description:
        "“I really like the Herb of the Day feature. I learned about herbs I hadn’t heard of before and loved that it included several different uses for them. “",
      avatar: avatarOne.childImageSharp.fixed.src,
    },
    {
      name: "Hagen",
      title: "Really helpful search feature.",
      position: "Herbalist",
      avatar: avatarTwo.childImageSharp.fixed.src,

      description:
        "“The search bar helped me find specific herbs and the information I needed. It gave me the information I needed quickly instead of having to browse through multiple web pages and being distracted by ads. “",
    },
    {
      name: "Jennie",
      position: "Herbalist",
      title: "Checked Multiple Symptoms",
      avatar: avatarThree.childImageSharp.fixed.src,

      description:
        " “It was easy to add my symptoms into the app and see possible causes. There were a lot of options for what could be causing the symptoms which was super helpful!“",
    },
  ]

  const renderRecommendations = reviews.map((review, index) => {
    return <ReviewItem key={index} review={review} />
  })

  return (
    <Container>
      <HeadingSmallLanding>What the community is saying</HeadingSmallLanding>
        <GridWrapper id="reviewsWrapper">
          <TheGrid id="reviewsGrid">
                {renderRecommendations}
          </TheGrid>
        </GridWrapper>
    </Container>
  )
}

export default Reviews
