import styled from 'styled-components'
import { BreakpointMobile, BreakpointTablet } from '../../GlobalStyle'

export const Container = styled.div`
    padding-top: 0px;
    padding-bottom: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  ${props => props.flipColumns? `grid-template-areas: "columnTwo columnOne";`: `grid-template-areas: "columnOne columnTwo";`};
    max-width: var(--LandingMaxPageWidth);
    padding-left: var(--LandingPagePaddingLeftRight);
    padding-right: var(--LandingPagePaddingLeftRight);
    height: ${props => props.showStepBottom? '600px;': '500px;'};
    /* border: 1px solid blue; */
    margin: auto;
    position: relative;
    grid-gap: 50px;

    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        ${props => props.flipColumns? `grid-template-columns: "0.7fr 1fr";`: `grid-template-columns: "1fr 0.7fr";`};
        height: 420px;
        grid-gap: 30px;
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        grid-template-columns: 1fr;
        height: initial;
        grid-template-areas: 
        "columnTwo"
        "columnOne";
        grid-gap: 20px;
    }
`

export const StepListItemTextColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 50px;

    h2, p{
        
        max-width: 450px;
        @media only screen and (max-width: ${BreakpointMobile + 'px'}){
            max-width: initial;
            margin-top: 0px;
            text-align: center;

        }
    }



    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 40px;
    }


`
export const StepListItemImage = styled.img`
    width: 100%;
    object-fit: cover;
    /* border: 1px solid blue; */
    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        width: 100%;
    }


    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        padding-top: 0px;
        width: 70%;
        margin: auto;
    }
`

export const ColumnOne = styled.div`
    position: relative;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    grid-area: columnOne;

    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        padding-top: 20px;
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        padding-top: 0px;
    }

`

export const ColumnTwo = styled.div`
    /* border: 1px solid blue; */
    position: relative;
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
    grid-area: columnTwo;
    /* ${props => props.flipColumns? `align-items: flex-start";`: `align-items: flex-end;`}; */


    @media only screen and (max-width: ${BreakpointTablet + 'px'}) {
        justify-content: center;
    }
`

export const StepBottom = styled.img  `
display: block;
    margin: auto;
    bottom: 10px;
    position: absolute;
    bottom: 30px;
    ${props => props.flipColumns? `left: 85%;  transform: rotate(90deg);`: `left: -35%;`};
    @media only screen and (max-width: ${BreakpointTablet + 'px'}) {
       display: none;
    }

`

export const StepTop = styled.img ` position: absolute;
    right: -35%;
    margin: auto;
    top: -120px;
    position: absolute;
    ${props => props.flipColumns? `left: 35%;`: `left: -35%;`};
    @media only screen and (max-width: ${BreakpointTablet + 'px'}) {
       display: none;
    }
    `